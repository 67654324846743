import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Item extends Component {

    data = this.props.Data;
    language = this.props.Language;

    render() {
        return (
            <>

                <div className="category-item" style={ { backgroundImage :`url(${this.data.cover})`, backgroundSize: 'cover', backgroundPosition: 'center' , filter:"brightness(0.9) "} }>
                    <Link to={"/menu/" + this.data['id']}>
                        <h2> 
                            {
                                this.props.Language == "en" ? this.data['name_eng']
                                : this.props.Language == "krd" ? this.data['name_krd']
                                : this.data['name_ar']
                            }
                        </h2>
                    </Link>
                </div>

                {
                    (  parseInt(this.props.Index) % 2 == 1 && this.props.Index != 0)
                    ?  <div className="seprator"></div>
                    : ""
                }
            </>
        );
    }
}

export default Item;